<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">学期:</div>
            <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container" @change="onSemester()">
                <el-option
                        v-for="item in this.$store.state.chinese.semester.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">出版社名称:</div>
            <el-select size="small" v-model="bookId" placeholder="请选择" class="select_container" @change="onBook()">
                <el-option
                        v-for="item in this.$store.state.chinese.bookName.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">单元名称:</div>
            <el-select size="small" v-model="unitId" placeholder="请选择" class="select_container">
                <el-option
                        v-for="item in this.$store.state.chinese.unitName.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">章节名称:</div>
            <el-input class="manage_input" type="text" size="small" v-model="chapterName"></el-input>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="oncancel">取消</el-button>

    </div>

</template>

<script>
    import axios from '@/libs/axios'
    import {compressFile} from "@/utils/fileUtil";

    export default {
        name: "add",

        data() {
            return {
                semesterId: '',//学期id
                bookId: '',//出版社id
                unitName: '',//单元名称
                chapterName:'',//章节名称
                unitId:'',//单元id
                loading: false,

            }
        },
        mounted() {
            this.$store.dispatch("chinese/semesterList");

        },
        methods: {
            //确认添加
            onConfirm() {
                if (!this.semesterId) {
                    this.$message.warning('请选择学期');
                } else if (!this.bookId) {
                    this.$message.warning('请选择出版社');
                } else if (!this.unitId) {
                    this.$message.warning('请选择单元名称');
                } else if (!this.chapterName) {
                    this.$message.warning('请输入章节单元名称');
                }else {
                    this.$store.dispatch("chinese/addChapter", {
                        'unitId': this.unitId,
                        'name': this.chapterName,
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }
            },
            //取消添加
            oncancel() {
                this.$router.go(-1);
            },
            //月份改变
            onSemester() {
                this.$store.dispatch('chinese/bookNameList', {'semesterId': this.semesterId})
            },
            //出版社改变
            onBook(){
                this.$store.dispatch('chinese/unitNameList', {'bookId': this.bookId})
            }


        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

        .image-upload, .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 12px;
            cursor: pointer;
            overflow: hidden;
            display: inline-block;
            width: 105px;
            height: 150px;

            .image {
                width: 105px;
                height: 150px;
                display: block;
            }

            .image-upload-icon {
                font-size: 28px;
                color: #8c939d;
                width: 105px;
                height: 150px;
                line-height: 150px;
                text-align: center;
            }
        }

    }

</style>
